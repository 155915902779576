import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import AddDevice from '../components/AddDevice'

import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import { Link } from 'gatsby';
import * as ROUTES from '../constants/routes';
import styles from './devices-list.module.scss';

const AddDevicePageBase = () => (
    <div className="page-content header-clear-medium content" >
        <AddDevice/>
    </div>
);

const condition = authUser => !!authUser;

const AddDevicePage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AddDevicePageBase);

export default () => (
  <Layout>
    <AddDevicePage />
  </Layout>
);
