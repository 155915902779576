import React, { Component, useState } from 'react';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import { Button, Container, Form, Modal, Icon, Header } from 'semantic-ui-react'

const AddDeviceForm = (props) => {
  const [devId,setDevId] = useState("")
  const [secret,setSecret] = useState("")
  const addDevice = ()=>{
    if(props.onAddDeviceButtonClick){
      props.onAddDeviceButtonClick({devId,secret}).then((success)=>{
        if(success){
          setDevId("")
          setSecret("")
        }
      })
    }
  }
  return(
  <Form success={true}>
    <Form.Field>
      <label>Device ID</label>
      <Form.Input placeholder='Device ID' 
        name='devId'
        value={devId}
        onChange={(e,{  value }) => setDevId(value) }/>
    </Form.Field>
    <Form.Field>
      <label>Secret Code</label>
      <Form.Input placeholder='Secret Code'
        name='secret'
        value={secret}
        onChange={(e,{  value }) => setSecret(value) }
      />
    </Form.Field>
    <Button color="blue" floated="right" type='submit' onClick={addDevice}>Add</Button>

  </Form>
  )
}

class AddDeviceBase extends Component {
  _initFirebase = false;
  state = {
    modalDetail: {
      success:false,
      header: '',
      text:''
    },
    modalOpen: false,
    loading: true,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  };
  constructor(props) {
    super(props);
  }

  updateSize = ()=>{ 
    // console.log(window.innerWidth + '-'+window.innerHeight)
    this.setState({    
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    })
  }

  firebaseInit = () => {
    if (this.props.firebase  && !this._initFirebase ) {
      this._initFirebase = true;
      this.setState({loading:false})

    }
  };

  componentDidMount() {   
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
    this.firebaseInit();
    
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.updateSize);
  }

  addDevice = async ({devId,secret})=> {
    console.log(`${devId} ${secret} ${this.props.authUser.uid}`)
    const result = await this.props.firebase.addDevice(devId,secret,this.props.authUser.uid) 
    console.log(result.data.result) 
    const success = result.data.result === "SUCCESS"
    this.setState({
      modalOpen:true,
      modalDetail:{
        success: true,
        header: result.data.result,
        text:result.data.result
      }
    })
    return success
  } 

  render() {
    const { modalOpen,modalDetail, loading } = this.state;
    // console.log(device);
    let chartsList = [];

    return (
          <>
            {loading && <div id="preloader"><div className="spinner-border color-highlight" role="status"></div></div>}
            {(!loading) && (
              <>
                <Container text>
                  <div className="card card-style ml-0 mr-0">
                    <div className="pt-3 pb-2 bg-blue1-light">
                      {/* <div className="card-overlay bg-20 card-blur-image" style={{zIndex: '-1'}}></div>
                      <div className="card-overlay bg-black opacity-50" style={{zIndex: '-1'}} ></div> */}
                      <h3 className="color-white text-center mb-0" > Add a new device </h3>
                      {/* <p className="color-black text-center opacity-80 mt-n1 mb-0">Add a VIP IoT device to my account</p> */}
                    </div> 
                  </div>
                </Container>
                <Container text>
                  <div className="card card-style  ml-0 mr-0 pl-0 pr-0 "> 
                      <div className="content">
                        <AddDeviceForm onAddDeviceButtonClick={this.addDevice}/>
                      </div>
                  </div>
                  <p>{modalDetail.text}</p>
                </Container>
              </>
              
            )}   
          </>
    );
  }
}

const AddDevice  = (props) =>  {
    return (
      <AuthUserContext.Consumer>
        { (authUser => <AddDeviceBase authUser={authUser} firebase={props.firebase}/> ) }  
      </AuthUserContext.Consumer>
    );
}

export default withFirebase(AddDevice);
